import React, { useState, useRef } from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

import useOutsideClicker from "../../../web-shared/hooks/useOutsideClicker";
import { emailInitials } from "../../../web-shared/utils";
import { HELP_PASS_URL } from "./constants"
import TransitionAnimation from "../Animation/TransitionAnimation";

const AvatarDropdown = ({ userInfo = null, onLogoutRequest = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const DropDownListContainerRef = useRef(null);
  const AvatarRef = useRef(null);

  useOutsideClicker(DropDownListContainerRef, (event) => {
    if (!AvatarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  })

  const toggling = () => setIsOpen(!isOpen);

  const {
    email = ""
  } = userInfo || {};

  const onLogoutClick = () => {
    onLogoutRequest();
    setIsOpen(false);
  }

  const onHelpClick = () => {
    window.open(HELP_PASS_URL, '_blank');
    setIsOpen(false);
  }

  const renderEmail = () => (
    <React.Fragment>
      <ListItem key="email" disabled data-tip data-for="email" notClickable={true}>
        <Email>{email}</Email>
      </ListItem>
      <ReactTooltip effect="solid" id="email" place="bottom">
        {email}
      </ReactTooltip>
    </React.Fragment>
  );

  return (
    <Wrapper>
      <Avatar ref={AvatarRef} onClick={toggling}>{emailInitials(email)}</Avatar>
      <TransitionAnimation trigger={isOpen} type="fade-down" timeout={200}>
        <DropDownListContainer ref={DropDownListContainerRef}>
          <DropDownList>
            {renderEmail()}
            <MenuDivider />
            <ListItem key="help" onClick={onHelpClick}>
              {__("Help")}
            </ListItem>
            <ListItem key="logout" onClick={onLogoutClick}>
              {__("Logout", "Log out")}
            </ListItem>
          </DropDownList>
        </DropDownListContainer>
      </TransitionAnimation>
    </Wrapper>
  );
}

export default AvatarDropdown;

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const Avatar = styled.div`
  cursor: pointer;
  font-size: 13px;
  width: 32px;
  height: 32px;
  margin: 0 0px 0 30px;
  border-radius: 100%;
  line-height: 32px;
  text-align: center;
  background-color: #ceccf6;
`;

const Email = styled.div`
  line-height: 36px;
  font-size: 15px;
  font-weight: bold;
`;

const DropDownList = styled("ul")`
  margin-top: 10px;
  width: 240px;
  border-radius: 9px;
  box-shadow: 2px 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  font-weight: normal;
  overflow: hidden;
`;

const ListItem = styled("li")`
  display: block;
  line-height: 36px;
  padding: 0 20px;
  max-height: 46px;
  font-family: "Roboto", sans-serif;
  font-size: 15px !important;
  color: #69696e !important;
  font-weight: normal !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  box-sizing: border-box;
  list-style: none;
  transition: all 200ms ease-in-out;
  cursor: ${ ({ notClickable }) => notClickable ? "auto" : "pointer"};

  div {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  &:hover {
    background-color: ${ ({ notClickable }) => notClickable ? "transparent" : "rgb(248, 247, 254)" };
  }
`;

const DropDownListContainer = styled("div")`
  position: absolute;
  right: 0px;
  top: 100%;
`;

const MenuDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f2f2f2;
`;
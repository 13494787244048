import styled, { keyframes } from "styled-components";

export const AnimationContainer = styled.div`
  width: 100%;

  .slide-enter {
    opacity: 0;
    transform: ${({ animationDirection }) => animationDirection && animationDirection === "right" ? "translateX(-150px)" : "translateX(150px)"};
  }
  .slide-enter-active {
    opacity: 1;
    transform: translateX(0px);
  }
  .slide-exit {
    opacity: 1;
    transform: translateX(0px);
  }
  .slide-exit-active {
    opacity: 0;
    transform: ${({ animationDirection }) => animationDirection && animationDirection === "right" ? "translateX(150px)" : "translateX(-150px)"};
  }
  .slide-enter-active,
  .slide-exit-active {
    transition: opacity ${({timeout}) => timeout}ms, transform ${({timeout}) => timeout}ms;
    transition-delay: ${({delay}) => delay}ms;
  }

  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-exit-active {
    transition: opacity ${({timeout}) => timeout}ms;
    transition-delay: ${({delay}) => delay}ms;
  }

  .fade-down-enter {
    opacity: 0;
    transform: translateY(-10px);
  }
  .fade-down-enter-active {
    opacity: 1;
    transform: translateY(0px);
  }
  .fade-down-exit {
    opacity: 1;
    transform: translateY(0px);
  }
  .fade-down-exit-active {
    opacity: 0;
    transform: translateY(-10px);
  }
  .fade-down-enter-active,
  .fade-down-exit-active {
    transition: all ${({timeout}) => timeout}ms;
  }

  .expand-enter {
    opacity: 0;
    max-height: 0px;
  }
  .expand-enter-active {
    opacity: 1;
    max-height: ${({height}) => height}px;
  }
  .expand-exit {
    opacity: 1;
    max-height: ${({height}) => height}px;
  }
  .expand-exit-active {
    opacity: 0;
    max-height: 0px
  }
  .expand-enter-active,
  .expand-exit-active {
    transition: all ${({timeout}) => timeout}ms;
    transition-delay: ${({delay}) => delay}ms;
  }

  .page-enter {
    .inner-animation.animation-type-slide {
      opacity: 0;
      transform: ${({ animationDirection }) => animationDirection && animationDirection === "right" ? "translateX(-150px)" : "translateX(150px)"};
    }

    .inner-animation.animation-type-fade {
      opacity: 0;
    }
  }
  .page-enter-active {
    .inner-animation.animation-type-slide {
      opacity: 1;
      transform: translateX(0px);
    }
    .inner-animation.animation-type-fade {
      opacity: 1;
    }
  }
  .page-exit {
    .inner-animation.animation-type-slide {
      opacity: 1;
      transform: translateX(0px);
    }
    .inner-animation.animation-type-fade {
      opacity: 1;
    }
  }
  .page-exit-active {
    .inner-animation.animation-type-slide {
      opacity: 0;
      transform: ${({ animationDirection }) => animationDirection && animationDirection === "right" ? "translateX(150px)" : "translateX(-150px)"};
    }
    .inner-animation.animation-type-fade {
      opacity: 0;
    }
  }
  .page-enter-active,
  .page-exit-active {
    .inner-animation.animation-type-slide {
      transition: opacity ${({timeout}) => timeout}ms, transform ${({timeout}) => timeout}ms;
      transition-delay: ${({delay}) => delay}ms;
    }
    .inner-animation.animation-type-fade {
      transition: opacity ${({timeout}) => timeout}ms;
      transition-delay: ${({delay}) => delay}ms;
    }
  }
  
`;

export const scale = keyframes`
  0%{
    transform: scale(1)
  }

  33%{
    transform: scale(0.96)
  }

  66%{
    transform: scale(1.12)
  }

  100% {
    transform: scale(1);
  }
`;
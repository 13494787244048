import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { rcookie, __ } from "../../lib/utils";
import AvatarDropdown from "./AvatarDropdown";
import SwitchAnimation from "../Animation/SwitchAnimation";

const UserPassHeader = ({
  userInfo: globalUserInfo = null,
  onLogoutRequest,
  onLoginRequest
}) => {
  const [passToken, setPassToken] = useState(rcookie("passtoken") || rcookie("restrictedPassToken"));
  const [userInfo, setUserInfo] = useState(globalUserInfo);
  
  useEffect(() => {
    login_callback = login_callback ? [].concat(login_callback) : [];
    register_callback = register_callback ? [].concat(register_callback) : [];
    pass_logout_callback = pass_logout_callback ? [].concat(pass_logout_callback) : [];

    pass_logout_callback.push(handleLogout);
    login_callback.push(handleLogin);
    register_callback.push(handleRegister);
  }, [])

  const handleLogout = () => {
    setPassToken("");
  }

  const handleLogin = (newUserInfo) => {
    setPassToken(rcookie("passtoken") || rcookie("restrictedPassToken"));
    setUserInfo(newUserInfo);
  }

  const handleRegister = (newUserInfo) => {
    setPassToken(rcookie("passtoken") || rcookie("restrictedPassToken"));
    setUserInfo(newUserInfo);
  }

  return (
    <SwitchAnimation trigger={!!(passToken && userInfo)}>
      {passToken && userInfo ? (
        <AvatarDropdown userInfo={userInfo} onLogoutRequest={onLogoutRequest}/>
      ) : (
        <Signin data-testid="signin-header-button" onClick={onLoginRequest}>{__("signin", "Sign in")}</Signin>
      )}
    </SwitchAnimation>
  )
};

export default UserPassHeader;

const Signin = styled.a`
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  margin-left: 30px;
  min-width: 82px;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #746de4;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
`; 
import React from "react";
import { CSSTransition } from "react-transition-group";
import { AnimationContainer } from "./AnimationContainer";

type AnimationProps = {
  children: any,
  trigger: any,
  type: "fade",
  timeout: number,
  height: number,
  delay: number,
  onEnter: () => void,
  onExited: () => void,
};

const TransitionAnimation = ({
  children,
  trigger = false,
  type = "fade",
  timeout = 500,
  height = 300,
  delay = 0,
  onEnter = () => { },
  onExited = () => { },
}: AnimationProps) => (
  <AnimationContainer timeout={timeout} height={height} delay={delay}>
    <CSSTransition
      in={trigger}
      timeout={timeout + delay}
      classNames={type}
      unmountOnExit
      onEnter={onEnter}
      onExited={onExited}
    >
      {children}
    </CSSTransition>
  </AnimationContainer>
);

export default TransitionAnimation;
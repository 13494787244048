import React from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { AnimationContainer } from "./AnimationContainer";

type AnimationProps = {
  children: any,
  trigger: any,
  type: "slide" | "fade" | "page",
  timeout: number,
  animationDirection: "left" | "right",
  style: any,
};

const SwitchAnimation = ({
  children,
  trigger = false,
  type = "fade",
  timeout = 500,
  animationDirection = "left",
  style = {},
}: AnimationProps) => (
  <AnimationContainer
    style={{ ...style }}
    timeout={timeout}
    animationDirection={animationDirection}
    className="animation-container"
  >
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={trigger}
        addEndListener={(node, done) => {
          node.addEventListener("transitionend", done, false);
        }}
        classNames={type || "fade"}
        timeout={timeout}
      >
        <div className="animation-wrapper">{children}</div>
      </CSSTransition>
    </SwitchTransition>
  </AnimationContainer>
);

export default SwitchAnimation;